<template>
  <v-dialog v-model="value" persistent max-width="700" scrollable>
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-4">{{ item.id ? "Editar" : "Cadastrar" }} Veículo de Frota<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="formVeiFrota" class="my-1">
          <div class="text-center">
            <v-avatar size="250" class="mb-7">
              <v-img
                :src="tempPicture"
                lazy-src="/img/nopic.jpg"
              ></v-img>
            </v-avatar>
            <Webcam
              @base64="tempPicture = $event"
              @file="item.picFile = $event"
            />
          </div>

          <v-text-field
            class="mt-2"
            v-model="item.plate"
            label="Placa"
            v-mask="plateMask"
            @input="plateToUppercase"
            :rules="requiredField"
            required
            outlined
          ></v-text-field>
          <v-text-field
            v-model="item.prefix"
            label="Prefixo"
            required
            outlined
          ></v-text-field>
          <v-text-field
            v-model="item.model"
            label="Modelo"
            :rules="requiredField"
            required
            outlined
          ></v-text-field>
          <v-textarea
            v-model="item.description"
            :counter="255"
            label="Descrição"
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-btn color="success" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Webcam from "@/components/Webcam/WbDialog.vue";
import { requiredField } from "../../../Utils/InputValidation";
export default {
  name: "DialogVeiFrota",
  components: {
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requiredField,
      tempPicture: "",
      plateMask: "",
      webcamDialog: false,
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    save() {
      if (!this.$refs.formVeiFrota.validate()) {
        return;
      }
      this.item.picture = this.tempPicture;
      this.$emit("save", this.item);
    },
    plateToUppercase() {
      if (!this.item.id) this.item.plate = this.item.plate.toUpperCase();
    },
  },
  watch: {
    value(value) {
      if (value){
        if (this.item.id)
          this.tempPicture = this.item.picture; // Utiliza a imagem em uma variável temporária para não alterar o estado antes de salvar
        else
          this.plateMask = "AAA-#N##"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
        
        this.$nextTick(() => {
          this.$refs.formVeiFrota.resetValidation();
        });        
      } else {
        this.tempPicture = "../img/nopic.jpg";
        this.plateMask = "";
      }
    }
  },
};
</script>

<style>
</style>