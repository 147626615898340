<template>
  <v-card>
    <v-card-title> Veículos da Frota </v-card-title>
    <v-card-text>
      <DataTable :headers="headers" :items="getFleetsVehicles">
        <!-- formata a data -->
        <template slot="item.createdAt" slot-scope="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template slot="item.updatedAt" slot-scope="{ item }">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <!-- imagem do avatar -->
        <template slot="item.picture" slot-scope="{ item }">
          <v-avatar size="70" class="my-2">
            <v-img
              :src="item.picture"
              lazy-src="/img/nopic.jpg"
            ></v-img>
          </v-avatar>
        </template>
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>
    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogForm = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Veículo</span>
    </v-tooltip>
    <Dialog v-model="dialogForm" :item="item" @close="item = {}" @save="salvar"/>
    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTableGeneric.vue";
import ConfirmDialog from '@/components/ConfirmDialog'
import Dialog from "./dialog_vei_frota.vue";
export default {
  name: "Visitantes",
  components: {
    DataTable,
    Dialog,
    ConfirmDialog,
  },
  computed: {
    ...mapGetters(["getFleetsVehicles"]),
  },
  data() {
    return {
      dialogForm: false,
      headers: [
        { text: "Foto", value: "picture", sortable: false, filterable: false, width: "70px" },
        { text: "Placa", value: "plate" },
        { text: "Prefixo", value: "prefix" },
        { text: "Modelo", value: "model" },
        { text: "Descrição", value: "description" },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: "85px" },
      ],
      item:{},
    };
  },
  methods: {
    editar(item) {
      this.item = item;
      this.dialogForm = true;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Veículo de Frota",
        text: `Deseja excluir o registro de <strong>${item.plate}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deletFleetVehicle", item);
        },
      });
    },
    salvar(item){
      item.callback = () => {
        this.dialogForm = false;
        this.item = {};
      };
      this.$store.dispatch("saveFleetVehicle2", item);
    }
  },
  created() {
    this.$store.dispatch("loadFleetsVehicles");
  },
};
</script>

<style>
</style>